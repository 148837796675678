/**
 * Universal Navigation - entry
 *
 * Note: all css imports need to be pulled in on this level,
 * otherwise tests will fail
 */
import '../../util/global-shim';
import { main } from './main/main';

import '../../../style/navigation/universal/universal.scss';
import '@rei/cedar/dist/cdr-fonts.css';

main(window);
