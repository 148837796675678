/**
 * Universal Navigation
 * Top bar navigation - navigates between sub-sites
 */

import initSkipLinks, { skipToNewPage } from '@rei/skip-link';
import initAutoCenter from '../lib/auto-center';
import analyticsInit from '../../../_shared-components/navigationMetrics';

export function app(win) {
  /* initialize universal navigation */
  initAutoCenter({
    list: '[data-js="unav-container"]',
    active: '[data-js="unav"] [data-active="true"]',
  });

  /* link to accessibility statement */
  skipToNewPage(['accessibility-statement']);

  /* initialize skip links */
  initSkipLinks({
    links: ['content', 'secondary-nav'],
  });

  analyticsInit('[data-js="unav"]');

  return win;
}

export function main(win = window) {
  if (win.document.readyState === 'loading') {
    // Loading hasn't finished yet
    win.document.addEventListener('DOMContentLoaded', app.bind(null, win));
  } else {
    // `DOMContentLoaded` has already fired
    app(win);
  }
}
