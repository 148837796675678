/**
 * Universal Navigation - Auto-center
 * Puts the active item in the center of the screen,
 * using the ends of the unav list as guardrails
 */

/**
 * coverts query selectors to elements or returns null
 * @param {object} selectors:
 * @property {string} selectors.list:   query selector for the list (ul) element
 * @property {string} selectors.active: query selector for the active item (li) element
 * @returns {object} dom elements:
 * @property unavList {Element}:        list (ul) dom element
 * @property activeItem {Element}:      active list item (li) dom element
 */
export const getDomElems = (selectors) => {
  const list = document.querySelector(selectors.list);
  const active = document.querySelector(selectors.active);
  if (list && active) {
    return {
      unavList: list,
      activeItem: active,
    };
  }
  return null;
};

/**
 * gets the coordinates needed from the elements found above
 * @param {object} elems:             keyed dom elements (getDomElems return value)
 * @returns {object}:
 * @property {number} activeLeft:     the left-hand coordinate of the active item
 * @property {number} activeWidth:    the width of the active item
 * @property {number} viewportWidth:  the width of the current viewport
 */
export const getElemCoords = (elems) => {
  const activeItemCoords = elems.activeItem.getBoundingClientRect();
  return {
    activeLeft: activeItemCoords.left,
    activeWidth: activeItemCoords.width,
    viewportWidth: elems.unavList.getBoundingClientRect().width,
  };
};

/**
 * checks if element is off screen
 * @param {object} coords: keyed coordinates (getElemCoords return value)
 * @returns {boolean}:     if element is even partially off screen, returns true
 */
export const getIsOffScreen = (coords) => {
  const { activeLeft, activeWidth, viewportWidth } = coords;
  return activeLeft + activeWidth > viewportWidth;
};

/**
 * get new scroll position (x coordinate)
 * @param {object} coords: keyed coordinates (getElemCoords return value)
 * @returns {number}:      the new x scroll coordinate for the unav list
 */
export const getNewPos = (coords) => {
  const { activeLeft, activeWidth, viewportWidth } = coords;
  return (activeLeft + (activeWidth / 2)) - (viewportWidth / 2);
};

/**
 * scroll to active element
 * @param elem:   dom element to scroll
 * @param newPos: the new x coordinate for that element
 */
export const scrollToActive = (elem, newPos) => {
  const element = elem;
  element.scrollLeft = newPos;
};

/**
 * Gets all needed dom elems or null
 * If elements exist, gets needed element coordinates
 * Calculates if the element is off screen
 * If element is off screen, calculates where the unav should scroll to
 * Scrolls to that position
 * See auto-center.spec.js for notes on tests
 */
/* istanbul ignore next */
const init = (selectors) => {
  const domElems = getDomElems(selectors);
  if (!domElems) return;
  const coords = getElemCoords(domElems);
  const isOffScreen = getIsOffScreen(coords);
  if (!isOffScreen) return;
  const newPos = getNewPos(coords);
  scrollToActive(domElems.unavList, newPos);
};

export default init;
